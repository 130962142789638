import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import appendQueryParamsToURL from '@pkgs/shared/helpers/appendQueryParamsToURL';
import { useRouter } from 'next/router';
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import SVRedirect from './SVRedirect';

const SVWithNoLogin = <P,>(
	WrappedComponent: React.ComponentType<P>,
	options: { forceLogout?: boolean } = {},
) => {
	const SVWithNoLogin = (props: P) => {
		const isLoggedIn = useIsLoggedIn();
		const router = useRouter();

		if (isLoggedIn) {
			const next = router.query.next ? String(router.query.next) : undefined;
			let redirectTo = next ? next : '/';

			if (options.forceLogout) {
				redirectTo = appendQueryParamsToURL('/logout/', { next: router.asPath });
			}

			return <SVRedirect to={redirectTo} />;
		}

		return <WrappedComponent {...(props as P & JSX.IntrinsicAttributes)} />;
	};

	SVWithNoLogin.displayName = wrapDisplayName(WrappedComponent, 'SVWithNoLogin');
	SVWithNoLogin.defaultProps = {
		next: '/',
	};

	return SVWithNoLogin;
};

export default SVWithNoLogin;
