import { type AssetImage, type SideItemQueryQuery } from '@apps/www/src/__generated__/graphql';
import { type CSSProperties } from 'react';
import SVA from './SVA';
import SVAvatar from './SVAvatar';
import SVLink from './SVLink';

type Props = {
	user: NonNullable<SideItemQueryQuery['sideItem']>['user'];
	color: CSSProperties['backgroundColor'];
	original: AssetImage['original'];
};

const SVSplitLayoutFeatured = ({ user, color, original }: Props) => (
	<div
		className="theme-dark text-secondary absolute inset-0 bg-cover bg-center bg-no-repeat"
		style={{ backgroundColor: color, backgroundImage: `url(${original})` }}
	>
		<div className="absolute inset-0 z-[10] bg-black/50" />
		<SVA
			className="group absolute left-6 bottom-6 z-[11] flex items-center"
			Component={SVLink}
			to={user.url}
			target="_blank"
			title={user.name}
		>
			<SVAvatar src={user.avatarURL} className="mr-3 h-7 w-7" />
			<span className="duration-over transition-colors ease-out group-hover:opacity-70">
				<span className="type-small text-muted">saved by </span>
				<span className="type-small">@{user.username}</span>
			</span>
		</SVA>
	</div>
);

SVSplitLayoutFeatured.itemToProps = (item: NonNullable<SideItemQueryQuery['sideItem']>): Props => ({
	color: item.asset.colors[0].color,
	original: item.asset.image.original,
	user: item.user,
});

export default SVSplitLayoutFeatured;
