import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type LoginFromEmailMutation,
	type LoginFromEmailMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const LoginFromEmail: TypedDocumentNode<
	LoginFromEmailMutation,
	LoginFromEmailMutationVariables
> = gql`
	mutation LoginFromEmail($input: LoginFromEmailInput!) {
		loginFromEmail(input: $input)
	}
`;

export default LoginFromEmail;
