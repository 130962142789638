import { useQuery } from '@apollo/client';
import SideItemQuery from '@apps/www/src/www/queries/SideItemQuery';
import SVSplitLayoutFeatured from '@pkgs/shared-client/components/SVSplitLayoutFeatured';

const SVSplitLayoutFeaturedContainer = () => {
	// Disable SSR here as it's not important to render it on the login/join pages
	const { data, loading, error } = useQuery(SideItemQuery, { ssr: false, errorPolicy: 'ignore' });
	const item = !loading && !error && data?.sideItem;

	if (!item) {
		return null;
	}

	return <SVSplitLayoutFeatured {...SVSplitLayoutFeatured.itemToProps(item)} />;
};

export default SVSplitLayoutFeaturedContainer;
