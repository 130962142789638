import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SideItemQueryQuery,
	type SideItemQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const SideItemQuery: TypedDocumentNode<SideItemQueryQuery, SideItemQueryQueryVariables> = gql`
	query SideItemQuery {
		sideItem {
			_id
			asset {
				_id
				colors {
					color
				}
				image {
					original
					ratio
				}
			}
			user {
				username
				name
				avatarURL
				url
			}
		}
	}
`;

export default SideItemQuery;
