import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import IconBackSVG from '@pkgs/shared-client/img/icon-back-inlined.svg';
import IconLogoSVG from '@pkgs/shared-client/img/logo-inlined.svg';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import SVCenter from './SVCenter';
import SVIconButton from './SVIconButton';
import SVLink from './SVLink';

const Logo = () => (
	<SVCenter>
		<SVIconButton
			className="text-primary mb-8"
			Component={SVLink}
			isCurrent={true}
			to="/"
			src={IconLogoSVG}
		/>
	</SVCenter>
);

const Title = ({ children }: OnlyChildrenProps) => (
	<h1 className="type-title mb-8 block w-full text-center">{children}</h1>
);

const SubTitle = ({ className, children }: React.PropsWithChildren<{ className?: string }>) => (
	<h2 className={clsx('type-subtitle my-12 block w-full text-center', className)}>{children}</h2>
);

const Center = ({
	top,
	bottom,
	children,
}: React.PropsWithChildren<{ top?: boolean; bottom?: boolean }>) => (
	<SVCenter className={clsx('my-6 w-full', top === false && 'mt-0', bottom === false && 'mb-3')}>
		{children}
	</SVCenter>
);

type Props = React.PropsWithChildren<{
	featured: JSX.Element | null;
	showBack?: boolean;
}>;

const SVSplitLayout = ({ featured, children, showBack }: Props) => {
	const [viewportKind] = useUIState(UIStateKeys.VIEWPORT_KIND);
	const router = useRouter();

	return (
		<div className="theme-dark flex md:fixed md:inset-0 md:flex md:overflow-hidden">
			{featured && viewportKind === ViewportKind.REGULAR && (
				<div className="-lg:w-1/2 relative hidden w-[60%] md:block">{featured}</div>
			)}
			<div className="type-small relative flex min-w-[320px] flex-1 flex-col items-center justify-center md:flex-grow md:overflow-y-auto">
				{showBack && (
					<SVIconButton
						className="absolute left-4 top-4"
						src={IconBackSVG}
						onClick={() => router.back()}
						label="Back"
					/>
				)}
				<div className="-sm:py-11 -sm:px-2 flex flex-col items-center justify-center px-10 py-20">
					{children}
				</div>
			</div>
		</div>
	);
};

SVSplitLayout.Logo = Logo;
SVSplitLayout.Title = Title;
SVSplitLayout.SubTitle = SubTitle;
SVSplitLayout.Center = Center;

export default SVSplitLayout;
