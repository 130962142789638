import SVSplitLayoutFeaturedContainer from '@apps/www/src/www/containers/SVSplitLayoutFeaturedContainer';
import SVSplitLayout from '@pkgs/shared-client/components/SVSplitLayout';
import { useRouter } from 'next/router';
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';

const SVWithSplitLayoutPage = <P,>(WrappedComponent: React.ComponentType<P>) => {
	const SVWithSplitLayoutPage = (props: P) => {
		const router = useRouter();

		const next = router.query.next ? String(router.query.next) : undefined;
		const isCourseCheckout = next?.includes('course/checkout');

		return (
			<>
				<SVSplitLayout
					showBack={
						router.asPath != '/login/reset-password/' &&
						router.asPath != '/join/complete/' &&
						router.asPath != '/logout/'
					}
					featured={isCourseCheckout ? null : <SVSplitLayoutFeaturedContainer />}
				>
					<WrappedComponent {...(props as P & JSX.IntrinsicAttributes)} />
				</SVSplitLayout>
			</>
		);
	};

	SVWithSplitLayoutPage.displayName = wrapDisplayName(WrappedComponent, 'SVWithSplitLayoutPage');

	return SVWithSplitLayoutPage;
};

export default SVWithSplitLayoutPage;
