import { useApolloClient } from '@apollo/client';
import { type LoginFromEmailMutationVariables } from '@apps/www/src/__generated__/graphql';
import SVPageMeta from '@apps/www/src/www/containers/SVPageMeta';
import SVWithNoLogin from '@apps/www/src/www/containers/SVWithNoLogin';
import SVWithSplitLayoutPage from '@apps/www/src/www/containers/SVWithSplitLayoutPage';
import LoginFromEmailMutation from '@apps/www/src/www/queries/LoginFromEmailMutation';
import SVA from '@pkgs/shared-client/components/SVA';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputText, SVInputTextUSES } from '@pkgs/shared-client/components/SVFormInputs';
import SVSplitLayout from '@pkgs/shared-client/components/SVSplitLayout';
import clientConfig from '@pkgs/shared-client/config';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconAppleSVG from '@pkgs/shared-client/img/apple-inlined.svg';
import IconEmailSVG from '@pkgs/shared-client/img/email-inlined.svg';
import IconFacebookSVG from '@pkgs/shared-client/img/facebook-inlined.svg';
import IconGoogleSVG from '@pkgs/shared-client/img/google.png';
import IconTwitterSVG from '@pkgs/shared-client/img/twitter-inlined.svg';
import SocialLoginProvider from '@pkgs/shared/enums/SocialLoginProvider';
import formatURL from '@pkgs/shared/helpers/formatURL';
import parseQueryParam from '@pkgs/shared/helpers/parseQueryParam';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import usePrevious from 'react-use/lib/usePrevious';

const _ButtonIcon = ({ method }: { method: ValueOf<typeof SocialLoginProvider> | 'email' }) => {
	switch (method) {
		case SocialLoginProvider.APPLE:
			return <IconAppleSVG className="h-4 w-4 flex-shrink-0" />;
		case SocialLoginProvider.FACEBOOK:
			return <IconFacebookSVG className="h-4 w-4 flex-shrink-0" />;
		case SocialLoginProvider.TWITTER:
			return <IconTwitterSVG className="h-4 w-4 flex-shrink-0" />;
		case SocialLoginProvider.GOOGLE:
			return (
				<img
					alt="Google logo"
					src={IconGoogleSVG}
					className="h-4 w-4 flex-shrink-0 object-contain"
				/>
			);
		case 'email':
			return <IconEmailSVG className="h-4 w-4 flex-shrink-0" />;
	}

	return null;
};

const _CircleButton = ({
	type,
	method,
	onClick,
}: {
	type?: 'submit' | 'button';
	method: ValueOf<typeof SocialLoginProvider> | 'email';
	onClick?: () => void;
}) => {
	return (
		<SVButton
			type={type}
			className="-sm:w-16 -sm:h-16 flex h-20 w-20 items-center justify-center rounded-full"
			use={SVButtonUSES.OUTLINE}
			size={SVButtonSIZES.WIDE}
			onClick={onClick}
		>
			<span className="min-h-4 min-w-4">
				<_ButtonIcon method={method} />
			</span>
		</SVButton>
	);
};

const _SVLoginPage = () => {
	const router = useRouter();
	const client = useApolloClient();
	const [loginWithEmailState, setLoginWithEmailState] = useState<'hidden' | 'form' | 'done'>(
		'hidden',
	);
	const previousLoginWithEmailState = usePrevious(loginWithEmailState);
	const emailInputRef = useRef<HTMLInputElement>(null);
	const meta = {
		title: 'Login',
	};

	const next = parseQueryParam(router.query.next) || '/';

	const isCourseCheckout = next.includes('course/checkout');

	const title = isCourseCheckout ? (
		<>
			Awesome!
			<br />
			<br />
			You will need an account to purchase and watch our episodes.
		</>
	) : loginWithEmailState !== 'done' ? (
		'Welcome'
	) : null;

	const handleContinueWithEmailClick = useEventCallback(() => {
		setLoginWithEmailState('form');
	});

	useEffect(() => {
		if (previousLoginWithEmailState !== 'form' && loginWithEmailState === 'form') {
			emailInputRef.current?.focus();
		}
	}, [previousLoginWithEmailState, loginWithEmailState]);

	const handleSubmit = useEventCallback(
		async (values: Omit<LoginFromEmailMutationVariables['input'], 'next'>) => {
			const login = await client.mutate({
				mutation: LoginFromEmailMutation,
				variables: {
					input: {
						...values,
						next,
					},
				},
			});

			if (login) {
				setLoginWithEmailState('done');
			}

			return login;
		},
	);

	return (
		<>
			<SVPageMeta {...meta} />
			<SVSplitLayout.Logo />

			{title ? (
				<SVSplitLayout.SubTitle className="max-w-[400px]">{title}</SVSplitLayout.SubTitle>
			) : null}

			<SVSplitLayout.Center>
				{loginWithEmailState === 'hidden' ? (
					<div className="flex max-w-80 flex-col gap-y-4">
						<div className="flex justify-center gap-x-4">
							<form
								action={formatURL(
									clientConfig.baseURL,
									'/api/auth/login/apple/?next=' + encodeURIComponent(next),
								)}
								method="post"
							>
								<_CircleButton type="submit" method="apple" />
							</form>
							<form
								action={formatURL(
									clientConfig.baseURL,
									'/api/auth/login/google/?next=' + encodeURIComponent(next),
								)}
								method="post"
							>
								<_CircleButton type="submit" method="google" />
							</form>
							{/* <form
							action={formatURL(
								clientConfig.baseURL,
								'/api/auth/login/facebook/?next=' + encodeURIComponent(next),
							)}
							method="post"
						>
							<SVButton
								type="submit"
								className="w-full flex-1 justify-center"
								use={SVButtonUSES.OUTLINE}
								size={SVButtonSIZES.WIDE}
								icon={<_ButtonIcon method="facebook" />}
							>
								Continue with Facebook
							</SVButton>
						</form> */}
							<_CircleButton
								type="button"
								method="email"
								onClick={handleContinueWithEmailClick}
							/>
						</div>
						<p className="mt-4">
							By continuing, you agree to our <SVA href="/terms">Terms</SVA> and{' '}
							<SVA href="/privacy">Privacy&nbsp;Policy</SVA>.
						</p>
					</div>
				) : loginWithEmailState === 'form' ? (
					<SVForm<
						Parameters<typeof handleSubmit>[0],
						Awaited<ReturnType<typeof handleSubmit>>
					>
						submitLabel="Continue"
						submitAlignment="center"
						submitSize={SVButtonSIZES.WIDE}
						submitUse={SVButtonUSES.PRIMARY}
						onSubmit={handleSubmit}
						initialValues={{
							email: '',
						}}
					>
						<SVInputText
							use={SVInputTextUSES.LOGIN}
							name="email"
							placeholder="Email or Username"
							required={true}
							ref={emailInputRef}
						/>
					</SVForm>
				) : (
					<SVSplitLayout.SubTitle className="max-w-[400px]">
						Check your email for a link to continue.
					</SVSplitLayout.SubTitle>
				)}
			</SVSplitLayout.Center>
		</>
	);
};

const SVLoginPage = SVWithNoLogin(SVWithSplitLayoutPage(_SVLoginPage));

// @ts-ignore fix this
SVLoginPage.withNavHidden = true;

export default SVLoginPage;
